<template>
  <div class="statistique-regie">
    <div class="body-box-rapport">
      <div class="repartition">
        <div class="title-repartition">
          <div class="title">Année</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>
        <div class="body-repartition">
          <div class="menu">
            <div
              @click="Regie.filterYearType = 'annee'"
              class="menu-item"
              :class="{ 'menu-item active': Regie.filterYearType == 'annee' }"
            >
              Par année
            </div>
            <div
              @click="Regie.filterYearType = 'intervalle'"
              class="menu-item "
              :class="{
                'menu-item active': Regie.filterYearType == 'intervalle'
              }"
            >
              Par intervalle d'année
            </div>
          </div>
          <div class="contenue">
            <b-form-select
              v-if="Regie.filterYearType == 'annee'"
              class="b-form-select  "
              v-model="Regie.Annee"
              :options="getListYearRegie"
              @change="handleChangeRegie"
            ></b-form-select>
            <template v-if="Regie.filterYearType == 'intervalle'">
              <b-form-select
                class="b-form-select raduis-left "
                v-model="Regie.Anne[0]"
                :options="getListYearRegie"
                @change="handleChangeRegie"
              ></b-form-select>
              <b-form-select
                class="b-form-select raduis-rigth"
                v-model="Regie.Anne[1]"
                :options="getListYearRegie"
                @change="handleChangeRegie"
              ></b-form-select
            ></template>
          </div>
        </div>
      </div>
      <div class="repartition">
        <div class="title-repartition">
          <div class="title">Semaine</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>
        <div class="body-repartition">
          <div class="menu">
            <div
              @click="Regie.filterSemaineType = 'semaine'"
              class="menu-item"
              :class="{
                'menu-item active': Regie.filterSemaineType == 'semaine'
              }"
            >
              Par Semaine
            </div>
            <div
              @click="Regie.filterSemaineType = 'intervalle'"
              class="menu-item "
              :class="{
                'menu-item active': Regie.filterSemaineType == 'intervalle'
              }"
            >
              Par intervalle de semaine
            </div>
          </div>
          <div class="contenue">
            <SelectComponent
              v-if="Regie.filterSemaineType == 'semaine'"
              :options="ComputedListWeekRegie"
              :value="Regie.Semaine"
              label="text"
              champName="Semaine"
              filterName="Semaine"
              :change="handleChangeRegie"
              :track-by="'text'"
              :max-heigh="130"
              placeholder="Rechercher"
              :searchable="true"
              :showLabels="false"
              classToUse="select-vue-component-style bg-select-vue"
            />
            <template v-if="Regie.filterSemaineType == 'intervalle'">
              <SelectComponent
                :options="ComputedListWeekRegie"
                :value="Regie.Semaine_min"
                label="text"
                champName="Semaine_min"
                filterName="Semaine_min"
                :change="handleChangeRegie"
                :track-by="'text'"
                :max-heigh="130"
                placeholder="Rechercher"
                :searchable="true"
                :showLabels="false"
                classToUse="select-vue-component-style select-vue-component-border-raduis-left bg-select-vue"
              />

              <SelectComponent
                :options="ComputedListWeekRegie"
                :value="Regie.Semaine_max"
                label="text"
                champName="Semaine_max"
                filterName="Semaine_max"
                :change="handleChangeRegie"
                :track-by="'text'"
                :max-heigh="130"
                placeholder="Rechercher"
                :searchable="true"
                :showLabels="false"
                classToUse="select-vue-component-style select-vue-component-border-raduis-right bg-select-vue"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="repartition repartitionRegie">
        <div class="title-repartition">
          <div class="title">Régie</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>
        <div class="contenue">
          <div class="body-repartition">
            <div class="contenue">
              <SelectComponent
                :options="computedgetAllRegie"
                :value="Regie.filterRegie"
                label="name"
                champName="régie"
                filterName="filterRegie"
                :change="handleChangeRegie"
                :track-by="'name'"
                placeholder="Rechercher"
                :searchable="true"
                :showLabels="false"
                classToUse="select-vue-component-style bg-select-vue"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="repartition repartitionFields">
        <div class="title-repartition">
          <div class="title">Statut Facture</div>
        </div>
        <div class="ligne-box-setting-header mt-0"></div>
        <template>
          <div class="body-repartition">
            <div class="contenue">
              <div class="palatte">
                <div
                  class="palatte-item"
                  v-for="(lab, index) in computedgetStatistuqueSalaryRegie"
                  :key="'Regie' + index"
                  :class="{
                    boxclick: lab.name === Regie.validation
                  }"
                  @click="
                    onClickEventRegie({
                      lab: lab,
                      backgroung: datasetRegie.backgroundColor[index]
                    })
                  "
                >
                  <div class="name">{{ lab.name | FormatName }}</div>
                  <div
                    class="pourcentage"
                    :style="
                      'background-color:' + datasetRegie.backgroundColor[index]
                    "
                  >
                    <template v-if="lab.name !== 'Tous'">{{
                      lab.pourcentage
                    }}</template>
                    <template v-if="lab.name === 'Tous'">{{
                      getTotalRegie[0]
                    }}</template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="box-rigth">
      <div class="table-rapport-style">
        <div class="chart-box-style">
          <Charts
            :label="computedlabelStatistuqueSalaryRegie"
            :data="computedDataStatistuqueSalaryRegie"
            :datasets="datasetRegie"
            :width="350"
            :height="350"
          />
        </div>
        <div
          v-if="seletedValidation"
          class="pourcentage pourcentageSelected"
          :class="ComputedClass"
        >
          <template>{{ seletedValidation.name | FormatName }}</template>
        </div>
      </div>
      <div class="box-montant">
        <div class="repartition">
          <div class="title">Total montant payé</div>
          <div class="ligne-box-setting-header mt-0"></div>
          <div class="valueBox">
            <div>{{ getTotalRegie[1] }} €</div>
          </div>
        </div>
        <div class="repartition">
          <div class="title">Total montant non payé</div>
          <div class="ligne-box-setting-header mt-0"></div>
          <div class="valueBox">
            <div class="bg-red ">{{ getTotalRegie[2] }} €</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      Regie: {
        validation: 'Tous',
        filterRegie: { name: 'tous', value: null },
        Anne: [],
        Annee: null,
        Semaine_min: null,
        Semaine_max: null,
        Semaine: null,
        ListYear: [],
        ListWeek: [],
        filterYearType: 'annee',
        filterSemaineType: 'semaine'
      },
      datasetRegie: {
        position: 'right',
        title: '',
        backgroundColor: [
          '#4f4dad',
          '#bbaaaa',
          '#ffdaa2',
          '#ffa2b2',
          '#08b9d4',
          '#ff3658',
          '#eca841',
          '#5ac8d9',
          '#78bc86'
        ],
        hoverBackgroundColor: [
          '#4f4dad',
          '#bbaaaa',
          '#ffdaa2',
          '#ffa2b2',
          '#08b9d4',
          '#ff3658',
          '#eca841',
          '#5ac8d9',
          '#78bc86'
        ]
      },
      seletedValidation: { name: 'Tous', background: '#4F4DAD' }
    };
  },
  components: {
    Charts: () => import('../component/StatistiqueComponent.vue'),
    SelectComponent: () => import('../SelectComponent.vue')
  },
  methods: {
    ...mapActions(['regieSalarysStatistic', 'fetchAllRegieForAdmin']),
    handleChangeRegie(payload) {
      if (payload) {
        this.Regie[payload.champName] = payload.value;
      }
      this.regieSalarysStatistic({
        employe: this.Regie
      });
    },
    onClickEventRegie({ lab, backgroung }) {
      this.Regie.validation = lab.name;
      this.seletedValidation = { backgroung, ...lab };
      this.regieSalarysStatistic({
        employe: this.Regie
      });
    }
  },
  computed: {
    ...mapGetters([
      'getStatistuqueSalaryRegie',
      'getAllRegie',
      'getTotalRegie'
    ]),
    getListYearRegie() {
      return this.Regie.ListYear;
    },
    ComputedListWeekRegie() {
      return this.Regie.ListWeek;
    },
    computedgetAllRegie() {
      return [{ id: null, name: 'tous' }, ...this.getAllRegie];
    },
    computedgetStatistuqueSalary() {
      return this.getStatistuqueSalary;
    },
    computedlabelStatistuqueSalaryRegie() {
      if (this.getStatistuqueSalaryRegie) {
        return this.getStatistuqueSalaryRegie.map(item => {
          return item.name;
        });
      }
      return [];
    },
    computedDataStatistuqueSalaryRegie() {
      if (this.getStatistuqueSalaryRegie) {
        return this.getStatistuqueSalaryRegie.map(item => {
          return item.pourcentage;
        });
      }
      return [];
    },
    computedgetStatistuqueSalaryRegie() {
      return this.getStatistuqueSalaryRegie;
    },
    ComputedClass() {
      if (this.seletedValidation && this.seletedValidation.name) {
        if (this.seletedValidation.name === 'en attend') {
          return 'enattend';
        }
        if (this.seletedValidation.name === 'encore de paiement') {
          return 'enattentedepaiement';
        }
        if (this.seletedValidation.name === 'encore de verification') {
          return 'encourdeverification';
        }
        if (this.seletedValidation.name === 'facture refuse') {
          return 'facturerefuse';
        }
        if (this.seletedValidation.name === 'facture valid') {
          return 'facturevalid';
        }
      }
      return this.seletedValidation.name;
    }
  },

  filters: {
    FormatName: value => {
      switch (value) {
        case 'valid':
          return 'Validé';
        case 'refuse':
          return 'Refusé';
        case 'encore de paiement':
          return 'En attente  de paiement';
        case 'paye':
          return 'Payé';
        case 'en attend':
          return 'En attente';
        default:
          return value;
      }
    }
  },
  mounted() {
    var courantYear = moment().year();
    this.Regie.Anne[0] = courantYear;
    this.Regie.Anne[1] = courantYear;
    this.Regie.Annee = courantYear;

    for (var i = 2019; i <= courantYear; i++) {
      this.Regie.ListYear.push({ value: i, text: i });
    }
    let isoWekk = moment().isoWeek();

    this.Regie.Semaine = { value: isoWekk, text: isoWekk };
    this.Regie.Semaine_min = { value: isoWekk, text: isoWekk };
    this.Regie.Semaine_max = { value: isoWekk, text: isoWekk };
    var totalWeekRegie = moment({
      year: this.Regie.filteryear
    }).isoWeeksInYear();
    for (var week = 1; week <= totalWeekRegie; week++) {
      this.Regie.ListWeek.push({ value: week, text: week });
    }

    this.fetchAllRegieForAdmin();
    this.regieSalarysStatistic({ employe: this.Regie });
  }
};
</script>

<style scoped lang="scss">
.statistique-regie {
  display: flex;
  position: relative;
  padding: 7px;
  .body-box-rapport {
    height: calc(100vh - 129px);
    margin: 0px;
    margin-right: 5px;
    width: 45%;
    display: inline;
    .repartitionRegie {
      height: 17%;
    }
    .repartitionFields {
      height: 33%;
    }
  }
  .box-rigth {
    width: 55%;
    height: calc(100vh - 129px);
    .table-rapport-style {
      width: 100%;
      justify-content: center;
      height: 79%;
      .pourcentage {
        margin: auto;
        margin-top: 1%;
        height: 28px;
        padding: 4px;
        text-align: center;
        width: 85px;
        border-radius: 22px;
        color: #fff;
        box-shadow: 0px 3px 6px #00000038;
      }
      .pourcentageSelected {
        min-width: 85px;
        width: auto;
      }
      .Tous {
        background-color: #4f4dad;
      }
      .enattend {
        background-color: #bbaaaa;
      }
      .valid {
        background-color: #ffdaa2;
      }
      .refuse {
        background-color: #ffa2b2;
      }
      .encourdeverification {
        background-color: #08b9d4;
      }
      .facturerefuse {
        background-color: #ff3658;
      }
      .facturevalid {
        background-color: #eca841;
      }
      .enattentedepaiement {
        background-color: #5ac8d9;
      }
      .paye {
        background-color: #78bc86;
      }
    }

    .box-montant {
      display: flex;
      margin-top: 7px;
      height: 20%;
      .repartition {
        margin: 5px;
        height: 87%;
        background-color: white;
        margin-bottom: 5px;
        width: 50%;
        border-radius: 20px;
        padding: 1% 6%;
        .title {
          color: #2a2a2a;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
        }
        .valueBox {
          align-content: center;
          widows: 100%;
          div {
            font-size: 15px;
            font-weight: 600;
            width: 60%;
            margin: auto;
            height: 30px;
            background-image: linear-gradient(
              to right,
              #43bf759e,
              #43bf75,
              #43bf75
            );
            color: #fff;
            border-radius: 22px;
            text-align: center;
          }
          .bg-red {
            background-image: linear-gradient(
              to right,
              #f3b4b4,
              #e04f4f,
              #e04f4f
            );
          }
        }
      }
    }
  }
}
</style>
